@charset "utf-8";

$sans-serif: "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", Arial, sans-serif;

body {
  font-family: $sans-serif;
}

$border-color: mix(#fff, #7a8288, 66%);

.full-width {
  .page, .archive, .breadcrumbs ol {
    float: left;
    width: 100%;
  }
  @media (min-width: 48em) { .grid__item .archive__item-teaser { max-height: 175px; } }
}

nav.nav__list {
  .nav__sub-title {
    display: flex;
    flex-direction: row;
    margin: 0;
    border-bottom: 0;

    &::after {
      flex-grow: 1;
      position: relative;
      height: 1px;
      content: '\a0';
      background-color: $border-color;
      top: .5rem;
      margin-left: 8px;
    }
  }

  > ul > li > ul li {
    margin-bottom: 8px;
    line-height: 1.1;

    &:last-child {
      margin-bottom: 6px
    }
  }
  /* li:last-child { /* The 'further resources' link, with no title
    .nav__sub-title {
      display: block;
      margin-bottom: .5rem;
      padding-top: 0;
      border-bottom: 1px solid $border-color;

      &::after {
        display: none;
      }
    }
  } */

  a[href^='https://']::after {
    content: "\f35d";
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: .8em;
    margin: 0 0 0 .25em;
  }

  a[href^='https://publications.cohubicol']::after {
    display: none
  }

  a:hover {
    text-decoration: underline
  }
}

.initial-content {
  .page__hero--overlay {
    margin-bottom: 1em
  }
}

input:focus, textarea:focus {
  box-shadow: none !important;
}

.archive div.entries-list div.list__item:first-child h2.archive__item-title {
  margin-top: 0;
}

#site-nav {

  .visible-links {
    align-items: center;

    li.mainnav-typology {
      padding: 6px 0;
      background: lighten(teal, 5%);
      border-radius: 3px;

      &:hover {
        background: teal;
      }

      a,a:visited {
        color: white !important;

        &:before {
          background: white;
        }
      }
    }

  }

  img {
    image-rendering: -webkit-optimize-contrast;
  }

  a.site-title {
    line-height: 1.25;
  }
}

h1.page__title {
  margin-bottom: 0;
}

.layout--news_post article h1.page__title {
  font-weight: normal;
  line-height: 1.15;
}

.page__hero--overlay p:last-child {
  margin-bottom: 0;
}

.sidebar {
  opacity: 1 !important;

  .taxonomy__count {
    float: right;
  }
}

.archive {
  margin-top: 0 !important;
}

.layout--single.singlepage--news-events article.page {
  @media (min-width: 48em) { padding-right: 200px }
}

.page__content .feature__item.latest__news {
  .archive__item {
    margin: .75em 0
  }
  h3.archive__item-title {
    margin: 0;
    font-size: .8em;
    font-weight: normal;
  }
  .page__meta {
    margin-top: 2px
  }
  > p:last-child {
    margin-top: 1em
  }
}

.page__content h2.archive__item-title {
  font-weight: normal;
}

.page__content .feature__item--center {
  p {
    font-size: 1.33em
  }
}

article.page .page__content p.small {
  font-size: .75em
}

.layout--news_post {
  .sidebar.sticky {
    height: auto;

    .page__meta {
      margin-top: 1.5em
    }
  }
  article h1.page__title {
    margin-bottom: .5em
  }
  .page__share {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;

    h4 {
      margin-top: .5em;
    }
  }
  .page__share + .pagination {
    .pagination--pager:last-child {
      border-left: 0;
    }
  }

  .page__related {
    padding-top: .5em
  }
}

.singlepage--research-blog article h2.archive__item-title {
  margin-bottom: 0
}

.layout--blog_post {
  .sidebar.sticky {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar { 
      display: none;
    }
  }
  .page__share {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;

    h4 {
      margin-top: .5em;
    }
  }
  .page__share + .pagination {
    .pagination--pager:last-child {
      border-left: 0;
    }
  }

  h1#page-title + .page__meta {
    margin-top: 2em;
  }

  .page__related {
    padding-top: .5em
  }

  .bio .author__avatar {
    vertical-align: middle;
    
    img {
      max-width: 150px;
      padding: 10px;
      background: #fff;
    }
    @media screen and (max-width: 64em) {
      img {
        padding: 0
      }
    }
  }
}

.grid__item {
    h2.archive__item-title {
      padding-bottom: 0
    }
    .archive__item-excerpt {
      display: block !important;
      font-size: 1em;
    }
    .archive__item {
      .page__meta {
        font-size: .75em
      }
    }
}

.archive__item {
  .sq {
    font-size: .8em;
    color: #bbb;
  }
}

.singlepage--news-events {
  .archive__item:first-child h2 {
    margin-top: 0
  }
  .archive__item {
    h2 {
      padding-bottom: 0;
      font-size: 1em;
    }
    .page__meta {
      font-size: .75em
    }
    .archive__item-excerpt {
      font-size: .85em
    }
  }
}

.bio {
  display: block;

  &:first-of-type {
    margin-top: 0 !important;
  }

  .member-title {
    display: block;
    margin-bottom: 12.5px;
    padding-bottom: 12.5px;
    border-bottom: 1px solid #d2d5d7;

    h2 {
      margin: 0;
      padding: 0;
      border: 0
    }
  }

  img {
    border-radius: 0 !important;
  }

  .author__bio {
    margin-top: 3px;
    font-size: .8em
  }

  .author__urls {
    li {
      margin: 0;
    }
    a {
      margin: 0
    }
  }
}


.featured__books {
  margin-bottom: 2em;
}

.book {
  display: block;
  clear: both;

  .cover {
    float: right;
    margin-bottom: 10px;

    img {
      max-width: 300px;
      padding: 10px;
      border: 1px solid #d2d5d7
    }

    @media (max-width: 37.5em) {
      display: block;
      float: none;
      margin: 10px 0;
      text-align: center;

      img {
        max-width: 60%;
      }
    }
    @media (min-width: 37.5em) {
      margin: 0 0 15px 15px;
    }
  }
}

article.splash .page__content {
  h2 {
    clear: both;
  }
  ol.references {
    padding-left: 1em;
    font-size: .9em;

    li {
      margin-bottom: .5em;
    }

    a.fas.fa-level-up-alt {
      text-decoration: none;
    }
  }
}

article.page .page__content {
  h2:first-child {
    margin-top: 0
  }

  p,li {
    font-size: .9em
  }

  .author__avatar {
    float: right;
    width: auto;
    height: auto;

    img {
      max-width: 175px;
      padding: 10px;
      border: 1px solid #d2d5d7
    }

    @media (max-width: 28em) {
      display: block;
      float: none;
      margin: 10px 0;
      text-align: center;

      img {
        max-width: 60%;
      }
    }
    @media (min-width: 28em) {
      margin: 0 0 15px 15px;
    }
  }

  a[href*="//"]::after,
  a[href$=".pdf"]::after,
  a[href*=".ppt"]::after {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: .8em;
    margin: 0 0 0 .25em;
  }
  a[href*="//"]:not([href*="cohubicol.com"])::after {
    content: "\f35d";
  }
  a[href$=".pdf"]::after {
    content: "\f1c1";
  }  
  a[href*=".ppt"]::after {
    content: "\f1c4";
  }
  a.btn::after {
    display: none !important;
  }
}

.page__content figure {
  display: block;
  margin: 0;
  padding: 0;
  text-align: center;

  img {
    max-width: 80%;
    margin-bottom: .5em;
    padding: .5em;
    border: 1px solid #e6e1e8;
  }

  figcaption {
    width: 100%;
    font-family: "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", Arial, sans-serif;
    font-style: italic;
    font-size: .8em;
  }

  &.right {
    float: right;
    max-width: 40%;
    margin: 0 0 .5em 1em;

    img {
      max-width: 100%;
    }

    @media screen and (max-width: 36em) {
      float: none;
      max-width: 100%;
      margin: 1em 0;
    }
  }
}

.page__content {
  sup[role='doc-noteref'] {
    font-size: 60%;
    font-style: normal !important;

    a:visited {
      color: #9ba1a6 !important;
    }
  }
  .footnotes {
    margin-bottom: 1em;

    li[role='doc-endnote'] {
      &::marker {
        font-size: .9em;
      }
      p {
        margin-bottom: 0
      }
    }
  }
}

hr {
  border-top: 1px solid mix(#fff, #7a8288, 66%) !important
}

.page__footer {
  background: #eee !important
}

.footer-flex {
  display: flex;
  align-items: center;

  @media (max-width: 37.5em) {
    display: block;
  }

  img.logo {
    width: 200px;
  }

  .page__footer-copyright {
    flex-grow: 1;
    margin-right: 25px;
    padding-right: 25px;
    border-right: 1px solid $border-color;

    @media (max-width: 37.5em) {
      margin: 0;
      padding: 0;
      border: 0
    }
  }
}

.footer-flex > div {
  align-self: flex-start;

  @media (min-width: 37.5em) and (max-width: 48em) {
    flex: 1
  }
  @media (max-width: 37.5em) {
    display: block
  }
}

.footer-flex .affs {
  text-align: right;

  img.logo {
    margin-bottom: 1em;
  }

  @media (min-width: 37.5em) and (max-width: 48em) {
    text-align: center
  }
  @media (max-width: 37.5em) {
    margin-top: 1em;
    text-align: left;
  }
}

.page__footer-follow li {
  font-size: 1em !important
}

.social-icons .fa-twitter,
.social-icons .fa-twitter-square,
.social-icons .fa-rss,
.social-icons .fa-rss-square {
  color: #494e52 !important;
}

.page__hero--overlay {
  .btn {
    margin-bottom: 0
  }

  #hero-name {
    max-width: 300px
  }

  &.homepage {
    .page__lead {
      margin: 15px 0 0;
    }
  }
}

.singlepage--research-team {
  .page__content .bio:first-child > h2 {
    margin-top: 0
  }
  .page__content .bio {
    margin-bottom: 2.5em
  }
}

.page__content {
  ul {
    padding-left: 1em;
    list-style-type: square;

    &.toc__menu {
      padding-left: 0;

      ul {
        padding-left: 0
      }

      li {
        font-size: 1em
      }
    }
  }
}

.page__content + .pagination {
  border: 0 !important;

  .pagination--pager {
    padding: .5em !important
  }
}

.page__hero-caption {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", Arial, sans-serif !important
}

.singlepage--bibliographies {
  .page__content {
    h2:first-of-type {
      margin-top: 0
    }
  }
}

.page__content ul.toc__menu {
  padding-left: 0;
}

.archive__item-title,
.archive__item-excerpt {
  .ais-Highlight {
    background: yellow
  }
  li {
    list-style-type: square;
    margin-left: 1em
  }
  a[href*="//"]::after,
  a[href$=".pdf"]::after,
  a[href*=".ppt"]::after {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: .8em;
    margin: 0 0 0 .25em;
  }
  a[href*="//"]:not([href*="cohubicol.com"])::after {
    content: "\f35d";
  }
  a[href$=".pdf"]::after {
    content: "\f1c1";
  }  
  a[href*=".ppt"]::after {
    content: "\f1c4";
  }
}

.page__content .latest__news {
  h2 {
    margin-top: 0;
    padding-bottom: 0;
    border-bottom: 0
  }
  h3 {
    font-size: .9em
  }
}

.ais-hits {
  article.archive__item {
    width: 100%;
    margin-bottom: 1.25em;

    h2 {
      font-size: 1em
    }

    p {
      margin-top: 0;
    }
  }
}

.btn--zotero{
  background-color:#cc2936;
  color:#fff
}

.btn--zotero:visited{
  background-color:#cc2936;
  color:#fff
}

.btn--zotero:hover{
  background-color:#d63340;
  color:#fff
}

.commento-root .commento-login-box-container .commento-login-box {
  background: #ddd
}

.commento-login-box {
  input,
  button {
    font-family: 'Roboto', sans-serif !important;
  }

  input.commento-input {
    margin-bottom: 0;
    box-shadow: none !important;

    &::placeholder {
      color: #888 !important
    }
  }
}

#comments-panel {
  clear: both;
  position: relative;

  h3.title {
    margin-top: 0;
    font-size: 1.33em;
  }

  .commento-root {
    .commento-blurred {
      filter: blur(2px)
    }
    .commento-card {
      border-top: 2px solid #ddd;
    }
  }
}

ul.abstract-list {
  display: flex;
  padding-left: 0;
  line-height: 1.25;
  font-size: .85em;

  > li {
    list-style: none;
    padding-right: .5em;
  }

  @media screen and (max-width: 64em) {
    display: initial
  }
}



@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials



/* conference page custom styles */
body.crcl22 {
  .notice.banner {
    margin-top: 0 !important
  }

  .feature__wrapper {
    margin-bottom: .5em;
    border: 0;
  }

  .page__content {

    @media screen and (min-width: 48em) {

      h3 {
        font-size: $type-size-5
      }

      p,
      ul:not(.toc__menu) li,
      ol li {
        font-size: $type-size-6;  
      }

      .large, ul.large li {
        font-size: $type-size-5;
      }

      .small {
        font-size: $type-size-6;
      }

      ul:not(.toc__menu) li,
      ol li {
        line-height: 1.15em;
      }

      ul.toc__menu ul li {
        font-size: 1em
      }
    }

    ul.columns {
      column-count: 2;

      @media screen and (min-width: 48em) {
        max-width: 60%;
      }
    }

    p.ref {
      margin-bottom: .5em;
      font-size: $type-size-7;
    }

    mark {
      padding: 3px;
      background-color: peachpuff !important
    }

  }

  /* Video embed styles -- from https://www.thomasvantuycom.com/writing/privacy-friendly-video-embeds/ */
  .video__iframe:not([src]) {
    display: none;
  }
  
  .video__iframe[src] + .video__notice {
    display: none;
  }

  .video {
    display: flex;
    align-items: center;
    position: relative;
    width: 560px;
    height: 315px;
    margin-top: 1rem;
    background-position: center center;
    background-size: cover;
    border: 3px solid #ccc;
  }
  
  .video::before {
    display: block;
    content: '';
    padding-bottom: 56.25%;
    width: 0;
    height: 0;
  }

  .video__notice {
    background-color: rgba(100, 100, 100, .9);
    text-align: center;
    padding: 1rem;
    width: 100%;
    color: #fff;

    a,a:visited {
      color: #ccc;
    }
  }
  
  .video__notice > * {
    max-width: 38rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .video__notice > button {
    font: inherit;
    padding: 0.5rem 1rem;
    background-color: rgb(200,50,50);
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .video__notice > button:hover,
  .video__notice > button:focus {
    background-color: #000;
  }
}

/* Programme */
body.crcl22.programme {
  .page__content {
    a.header-link {
      display: none
    }
    
    .date-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      clear: both;
      margin: 2em 0 0.5em;
      padding-bottom: .5em;
      border-bottom: 3px solid #d2d5d7;

      h2 {
        flex: 1 auto;
        margin: 0;
        padding: 0 0 0 15%;
        font-size: 1.75em;
        font-weight: 400;
        text-align: center;
        border: 0;
      }
      .stream {
        flex: 0 15%;
        text-align: right;
        align-self: end;
        // a {
        //   @media screen and (max-width: 36em) {
           
        //   }
        // }
      }

      @media screen and (max-width: 48em) {
        h2 {
          flex: 1 75%;
          padding-left: 0;
          text-align: left;
        }
        .stream {
          flex: 0 25%
        }
      }

      @media screen and (max-width: 36em) {
        flex-direction: column;

        h2, .stream {
          flex: 1 100%;
          text-align: center;
          align-self: auto;
        }
        .stream {
          margin-top: .5em
        }
      }
    }
    dl {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 5%;

      dt {
        flex: 0 0 15%;
        margin: 0 0 1em;
        line-height: 1.25em;
        font-size: 1em;
        font-weight: 400;
        text-align: right;

        &:last-of-type {
          margin-bottom: 0;
        }
        
        @media screen and (max-width: 48em) {
          margin-bottom: 0 !important
        }

        &.keynote {
          @media screen and (max-width: 48em) {
            &::after {
              content: 'Keynote';
              display: inline-block;
              position: relative;
              top: -2px;
              margin-left: 10px;
              padding: 2px 8px;
              line-height: 1.5rem;
              vertical-align: text-bottom;
              font-weight: 500;
              font-size: .7rem;
              text-transform: uppercase;
              color: #fff;
              border-radius: 3px;
              background: limegreen;
            }
          }  
        }

        @media screen and (max-width: 48em) {
          flex: 1 0 100%;
          margin-bottom: .5em;
          font-size: 1.5em;
          text-align: left;
        }
      }

      dd {
        flex: 1 0 70%;
        margin: 0 0 1em;
        padding-bottom: 1em;
        border-bottom: 1px dotted #666;

        &:last-of-type {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: 0;
        }

        @media screen and (max-width: 48em) {
          flex: 1 0 100%;
          font-size: .8em;
          border-bottom-color: #666;
        }

        h3 {
          margin: 1.5rem 0 0;
          line-height: 1.25em;
          font-size: 1rem;
          font-weight: 500;

          &:first-child {
            margin-top: 0
          }
        }  
          
        img {
          float: right;
          max-width: 160px;
          margin: 0 0 .5em 2em;
          padding: .5em;
          border: 1px solid #efefef;

          // @media screen and (max-width: 48em) {
          //   max-width: 120px;
          //   margin: 0 0 5px 10px;
          //   padding: .25em
          // }

          @media screen and (max-width: 48em) {
            max-width: 100px;
            margin: 10px;
            padding: .25em
          }
        }

        > p {
          margin: .5em 0 .5em;
          font-size: 1.1em !important;

          @media screen and (max-width: 48em) {
            margin-top: 0
          }
        }

        p {
          font-size: 1em
        }

        &.paper {
          h3 {
            &::before {
              content: "Paper discussion" '\A';
              display: block;
              line-height: 1 rem;
              font-weight: 400;
              font-size: .7rem;
              color: #777;
              text-transform: uppercase;
            }
          }
        }
        &.keynote {
          > span {
            display: inline-block;
            padding: 2px 8px;
            font-weight: 500;
            font-size: .7rem;
            text-transform: uppercase;
            color: #fff;
            border-radius: 3px;
            background: limegreen;

            @media screen and (max-width: 48em) {
              display: none
            }
          }
          h3 {
            margin: .5rem 0;
            font-size: 1.5rem;

            @media screen and (max-width: 48em) {
              font-size: 1.33rem;
            }
          }
        }
      }
    }

    details {
      text-align: justify;

      p:last-of-type {
        margin-bottom: 0
      }
    }

    summary {
      cursor: pointer;
      font-weight: 500;
      color: #2f7d95 !important;

      &:hover {
        color: lighten(#333, 10%);
      }
    }

    p.break {
      margin: 1em 0;
      padding: 8px 0;
      text-align: center;
      background-color: #f4f4f4;
    }

    .conf-buttons {
      display: flex;
      flex-direction: row;
      margin-bottom: 1em;
      padding: 0 10%;

      .left {
        flex: 2;
      }
      .right {
        flex: 1;
        text-align: right;
      }

      @media screen and (max-width: 48em) {
        flex-direction: column;
        padding: 0;
        
        .left,.right {
          flex: 1 100%;
          text-align: center;
        }
      }
    }

  }
  &.crcl23 {
    .page__content {
      h2 {
        font-size: 1.5em
      }
    }
  }
}